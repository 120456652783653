import React from 'react';

const NotFoundPage = () => (
  <div style={{ minHeight: '100vh', background: '#fff' }}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist</p>
  </div>
);

export default NotFoundPage;
